import { memo } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { REGEXP_ONLY_DIGITS_AND_CHARS } from 'input-otp';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { otpRecover } from '@/features/auth/schema';

import { core, messages } from '@/features/dashboard/localization';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl, FormField, FormItem, FormMessage,
} from '@/components/ui/form';
import {
  InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot,
} from '@/components/ui/input-otp';

interface Props {
  action: (values: z.infer<any>) => void,
  isDisabled: boolean,
}

const ForgotPasswordPhoneOtp = ({ action, isDisabled }: Props) => {
  const form = useForm<z.infer<any>>({
    resolver: zodResolver(otpRecover),
    defaultValues: {
      opt: '',
    },
    mode: 'onChange',
  });
  return (
    <Form
      watch={form.watch}
      getValues={form.getValues}
      getFieldState={form.getFieldState}
      setError={form.setError}
      clearErrors={form.clearErrors}
      setValue={form.setValue}
      trigger={form.trigger}
      formState={form.formState}
      resetField={form.resetField}
      reset={form.reset}
      handleSubmit={form.handleSubmit}
      unregister={form.unregister}
      control={form.control}
      register={form.register}
      setFocus={form.setFocus}
    >
      <form onSubmit={form.handleSubmit(action)}>
        <p className="mb-5">{messages.enterYourMobilePhone}</p>
        <FormField
          control={form.control}
          name="otp"
          render={({ field }) => {
            return (
              <FormItem className="w-full">
                <FormControl className="w-full">
                  <div className="w-full flex flex-row items-center justify-center">
                    <InputOTP
                      maxLength={6}
                      pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
                      className="w-full justify-self-center [border:none] [outline:none] bg-gray self-stretch h-10 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-lg flex flex-row items-center py-[15px] px-3.5 box-border font-text-sm-medium font-medium text-sm text-darkslategray min-w-[212px]"
                      onChange={field.onChange}
                      value={field.value}
                      name={field.name}
                      onBlur={field.onBlur}
                    >
                      <InputOTPGroup>
                        <InputOTPSlot index={0} />
                        <InputOTPSlot index={1} />
                        <InputOTPSlot index={2} />
                      </InputOTPGroup>
                      <InputOTPSeparator />
                      <InputOTPGroup>
                        <InputOTPSlot index={3} />
                        <InputOTPSlot index={4} />
                        <InputOTPSlot index={5} />
                      </InputOTPGroup>
                    </InputOTP>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <Button
          type="submit"
          disabled={isDisabled}
          className="mt-4 w-full bg-[#95fb3f] hover:bg-[#95fb3f] text-sm leading-[10px] font-medium font-text-sm-medium text-sivarbet-background text-center inline-block"
        >
          {isDisabled ? core.validating : messages.validateOTP }
        </Button>
      </form>

    </Form>
  );
};

export default memo(ForgotPasswordPhoneOtp);
