'use client';

import React, { useState } from 'react';

import { IoChevronBackOutline } from 'react-icons/io5';

import { z } from 'zod';

import ForgotPasswordChange from '@/features/auth/components/ForgotPasswordChange';
import ForgotPasswordEmail from '@/features/auth/components/ForgotPasswordEmail';
import ForgotPasswordPhone from '@/features/auth/components/ForgotPasswordPhone';

import ForgotPasswordPhoneOtp from '@/features/auth/components/ForgotPasswordPhoneOtp';
import { handleSendPhoneCode } from '@/features/auth/services';

import { errors, messages } from '@/features/dashboard/localization';

import { exception } from '@/utils/core';

import { Button } from '@/components/ui/button';

import { useToast } from '@/components/ui/use-toast';

interface ForgotPasswordProps {
  onBack: () => void;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ onBack }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [whichFormToUse, setWhichFormToUse] = useState<{ email: boolean, phone: boolean }>({ email: false, phone: false });
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const { toast } = useToast();

  const [phoneNumberIsBeingValidated, setPhoneNumberIsBeingValidated] = useState<boolean>(false);
  const [otpIsBeingValidated, setOtpIsBeingValidated] = useState<boolean>(false);
  const [passwordChangeIsBeingValidated, setPasswordChangeIsBeingValidated] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [userUid, setUserUid] = useState<string>('');
  const [otp, setOtp] = useState<any>(null);

  const handleChangeForm = (type: 'email' | 'phone') => {
    setWhichFormToUse({ email: type === 'email', phone: type === 'phone' });
  };

  const handleEmailForgotPassword = async (values: z.infer<any>): Promise<void> => {
    try {
      setIsLoading(true);
      const response = await fetch('/api/reset-oobcode', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: values.email }),
      });

      const res = await response.json();

      if (res.isError) {
        toast({
          title: errors.emailVerificationSent,
          description: res.counter === 0 ? res.message : errors.msgPassEmailVerificationSent,
          variant: 'destructive',
        });
      } else {
        toast({
          title: messages.emailSentSuccessfully,
          description: messages.emailResetPasswordSent,
          variant: 'default',
          className: 'bg-sivarbet-secondary',
        });
        setTimeout(onBack, 3000);
      }
    } catch (error) {
      await exception(error, { route: 'ForgotPassword.tsx', method: 'FRONT', req: '' });

      toast({
        title: errors.emailVerificationSent,
        description: errors.msgPassEmailVerificationSent,
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendCodeToPhone = async (value:string): Promise<void> => {
    try {
      const result = await handleSendPhoneCode(value);
      if (result && typeof result === 'object' && 'verificationId' in result) {
        setOtp(result);
      } else if (result && typeof result === 'object' && 'message' in result && 'className' in result) toast({ title: result.message, className: result.className });
      else {
        setPhoneNumberIsBeingValidated(false);
        toast({ title: errors.tryAgain, variant: 'destructive' });
      }
    } catch (error) {
      setPhoneNumberIsBeingValidated(false);
      toast({
        title: errors.tryAgain,
        variant: 'destructive',
      });
    }
  };

  const checkIfPhoneNumberIsRegistered = async (values: z.infer<any>): Promise<void> => {
    try {
      setPhoneNumberIsBeingValidated(true);
      const currentPhoneNumber = values.phone.includes('+') ? values.phone : `+${values.phone}`;
      setPhoneNumber(currentPhoneNumber);
      const request = await fetch(`/api/credentials-reset?value=${currentPhoneNumber.substring(1)}`);
      const response = await request.json();
      if (response.status && response.value !== '') {
        setUserUid(response.value);
        await handleSendCodeToPhone(currentPhoneNumber);
      } else {
        setPhoneNumberIsBeingValidated(false);
        toast({
          title: errors.phoneNumberNotFound,
          variant: 'destructive',
        });
      }
    } catch (error) {
      setPhoneNumberIsBeingValidated(false);
      toast({
        title: errors.tryAgain,
        variant: 'destructive',
      });
    }
  };

  const handleValidateOtp = async (values: z.infer<any>): Promise<void> => {
    try {
      setOtpIsBeingValidated(true);
      const phoneCredential = await otp?.confirm(values.otp);
      if (phoneCredential) {
        setShowNewPassword(true);
      } else toast({ title: errors.tryAgain, variant: 'destructive' });
    } catch (error) {
      setOtpIsBeingValidated(false);
      toast({ title: errors.tryAgain, variant: 'destructive' });
    }
  };

  const handleChangePassword = async (values: z.infer<any>): Promise<void> => {
    try {
      setPasswordChangeIsBeingValidated(true);
      const result = await fetch('/api/credentials-reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          password: values.password, passwordConfirm: values.passwordConfirm, phone: phoneNumber, token: userUid,
        }),
      });
      const response = await result.json();
      if (response.status) {
        toast({
          title: messages.passwordChangedSuccessfully,
          variant: 'default',
          className: 'bg-sivarbet-secondary',
        });
        setTimeout(onBack, 3000);
      } else {
        setPasswordChangeIsBeingValidated(false);
        toast({ title: errors.passwordChangedUnsuccessfully, variant: 'destructive' });
      }
    } catch (error) {
      setPasswordChangeIsBeingValidated(false);
      toast({ title: errors.tryAgain, variant: 'destructive' });
    }
  };

  return (
    <div className="mt-5 border-none mx-3 lg:mr-3 md:mx-3">

      {whichFormToUse.email && (
        <ForgotPasswordEmail
          action={handleEmailForgotPassword}
          isDisabled={isLoading}
        />
      )}

      {whichFormToUse.phone && otp === null && !showNewPassword && (
        <ForgotPasswordPhone
          action={checkIfPhoneNumberIsRegistered}
          isDisabled={phoneNumberIsBeingValidated}
        />
      )}
      {whichFormToUse.phone && otp !== null && !showNewPassword && (
        <ForgotPasswordPhoneOtp
          action={handleValidateOtp}
          isDisabled={otpIsBeingValidated}
        />
      )}

      {whichFormToUse.phone && otp !== null && showNewPassword && (
        <ForgotPasswordChange
          action={handleChangePassword}
          isDisabled={passwordChangeIsBeingValidated}
        />
      )}

      <div className="flex flex-row gap-4 mt-16">
        <Button
          type="button"
          onClick={onBack}
          className="w-full bg-transparent hover:bg-transparent text-sm leading-[10px] font-medium font-text-sm-medium text-white text-center min-w-[35px] flex-row gap-2"
        >
          <IoChevronBackOutline />
          <span className="text-slate-500">Volver</span>
        </Button>

        <Button
          className="w-full bg-sivarbet-primary hover:bg-sivarbet-primary-hover text-sivarbet-background"
          onClick={() => { return handleChangeForm('email'); }}
          disabled={whichFormToUse.email}
        >
          {messages.withEmail}
        </Button>

        <Button
          className="w-full bg-sivarbet-primary hover:bg-sivarbet-primary-hover text-sivarbet-background"
          onClick={() => { return handleChangeForm('phone'); }}
          disabled={whichFormToUse.phone}
        >
          {messages.withPhone}
        </Button>
      </div>

    </div>
  );
};

export default ForgotPassword;
