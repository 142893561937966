'use client';

import React, { useState, useEffect } from 'react';

import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';

import { IoMenuSharp, IoCloseOutline } from 'react-icons/io5';

import { errors } from '@/localization';
import { userUIStore } from '@/stores/userUIStore';

import LoginModal from '@/components/modals/LoginModal';
import RegisterModal from '@/components/modals/RegisterModal';
import { Button } from '@/components/ui/button';

import LogoL from '@/public/img/logo/hondubetlogo.png';
import LogoS from '@/public/img/logo/hondubetsquare.png';

import { useToast } from '../ui/use-toast';

const PublicNavBar = () => {
  const { toggleSidebar, resetSidebar } = userUIStore();
  const [isActive, setActive] = useState(false);
  const [isCredentialsSigninError, setIsCredentialsSigninError] = useState<boolean>(false);
  const [isWebView, setIsWebView] = useState<boolean | null>(null);

  const pathname = usePathname();
  const searchParams = useSearchParams();

  const { toast } = useToast();

  const toggleActive = () => {
    setActive(!isActive);
  };

  const handleHeaderBarClick = () => {
    if (window.innerWidth > 768) {
      toggleActive();
      toggleSidebar();
    }
  };

  useEffect(() => {
    resetSidebar(); // Reset the sidebar state whenever the route changes
  }, [pathname, resetSidebar, searchParams]); // Reacting to changes in path or search params

  if (searchParams && searchParams.get('error') === 'CredentialsSignin' && !isCredentialsSigninError) {
    setIsCredentialsSigninError(true);
    toast({ title: errors.tryAgain, variant: 'destructive' });
  }

  const handleCheckIfIsAWebview = async () => {
    try {
      const request = await fetch('/api/webview', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const response = await request.json();
      const status = response?.status;
      setIsWebView(status);
    } catch (error) {
      setIsWebView(false);
    }
  };

  useEffect(() => {
    if (isWebView === null) handleCheckIfIsAWebview();
  }, [isWebView]);

  return (
    <header className="bg-sivarbet-background flex w-full">
      <div className="container-fluid p-0 flex-1">
        <div className="header-wrapper  flex justify-between items-center p-2 px-4 bg-gray-900 text-sivarbet-text border-b border-b-sivarbet-border shadow drop-shadow-lg shadow-black">
          <div
            className="pl-2 pr-9 flex justify-center items-center transition-all duration-1000 max-md:hidden"
          >
            <Button
              onClick={handleHeaderBarClick}
              variant="default"
              className="bg-transparent border-none p-0 hover:bg-none"
            >
              {!isActive ? (
                <IoMenuSharp className="text-4xl text-slategray transition-all duration-1000" />
              ) : (
                <IoCloseOutline className="text-4xl text-slategray transition-all duration-1000" />
              )}
            </Button>
            <Link href="/">
              <Image src={LogoL} alt="logo" width={198} height={90} className="ml-4" />
            </Link>
          </div>
          <div className="max-md:block hidden">
            <Link href="/">
              <Image src={LogoS} alt="logo" width={40} height={40} className="" />
            </Link>
          </div>
          <div className="flex gap-2">

            {isWebView !== null && (
              <>
                <RegisterModal isDefaultOpen={isCredentialsSigninError} isWebView={isWebView} />
                <LoginModal isWebView={isWebView} />
              </>
            )}

          </div>
        </div>
      </div>
    </header>
  );
};

// const PublicNavBarWithSuspense = () => {
//   return (
//     <Suspense fallback={<div>Loading...</div>}>
//       <PublicNavBar />
//     </Suspense>
//   );
// };
export default PublicNavBar;
