export const messages = {
  emailSentSuccessfully: 'Correo enviado exitosamente',
  emailVerificationSent: 'Correo de verificacion enviado correctamente, revisa tu bandeja de entrada',
  emailResetPasswordSent: 'Correo de reseteo de contraseña enviado correctamente, revisa tu bandeja de entrada',
  firstDeposit: 'Realice su primer depósito para comenzar a jugar y aprovechar nuestras ofertas y promociones.',
  identityVerification: 'Proporcione documentos de identificación para confirmar su identidad y acceder a todas las funciones de la cuenta.',
  completeProfileA: 'Complete su perfil proporcionando información adicional para una experiencia personalizada.',
  completeProfileB: 'Complete su perfil verificando su email para una experiencia personalizada.',

  accountSettingsA: 'Agrega más seguridad a tu cuenta de hondubet cuando inicias sesión con correo electrónico / contraseña.',
  accountSettingsGoogle: 'Si tu cuenta de hondubet fue creada con Google comunicate con soporte para hacer cambios en ella.',
  accountSettingsB: 'Agrega más seguridad a tu cuenta de hondubet cuando inicias sesión con correo electrónico / contraseña. Se enviará un código de verificación a tu correo electrónico cada vez que inicies sesión para proteger tu cuenta de forma segura.',
  accountSettingsC: 'Agrega más seguridad a tu cuenta de hondubet cuando inicias sesión con correo electrónico / contraseña. Se enviará un código de verificación a tu correo electrónico cada vez que inicies sesión para proteger tu cuenta de forma segura.',
  accountSettingsD: '¿Quiere desactivar temporalmente su cuenta? contact a nuestro equipo de soporte.',
  accountSettingsE: 'Si, desactiva mi cuenta',
  emailChangeRequest: 'Se ha enviado la solicitud de cambio de correo, revisa la bandeja de entrada',
  errorInvalidEmail: 'El correo electrónico es inválido',

  passwordEmailSentA: 'Correo de cambio de contraseña enviado',
  passwordEmailSentB: 'Por favor, revisa tu bandeja de entrada',
  passwordEmailNotSentA: 'Error al enviar el correo',
  passwordEmailNotSentB: 'No se pudo enviar el correo de cambio de contraseña, intentalo de nuevo',

  requestFailedA: 'Error al procesar la solicitud',
  requestFailedB: 'Por favor, inténtalo de nuevo más tarde',
  withEmail: 'Con email',
  withPhone: 'Con teléfono',
  enterYourEmail: 'Ingresa tu correo electrónico con el que te registraste para recuperar tu contraseña',
  enterYourMobilePhone: 'Ingresa el correo teléfonico con el que te registraste para recuperar tu contraseña',
  enterYourMobilePhoneCode: 'Ingresa el código de verificación enviado a tu teléfono para recuperar tu contraseña',
  validatePhoneNumber: 'Validar teléfono',
  validateOTP: 'Validar código',
  password: 'Contraseña',
  enterYourPassword: 'Ingresa tu contraseña',
  enterYourPasswordConfirm: 'Ingresa tu contraseña nuevamente',
  changePassword: 'Cambiar contraseña',
  passwordChangedSuccessfully: 'Contraseña cambiada correctamente',
};
