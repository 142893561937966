/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */

'use client';

import { useEffect, useState } from 'react';

import { usePathname, useRouter } from 'next/navigation';

import { zodResolver } from '@hookform/resolvers/zod';
// import { Session } from 'next-auth';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { AppStyles } from '@/enums/core';
import { FormRegisterPopUp } from '@/features/modals/components';
import { messages } from '@/features/modals/localization';
import { formSchema } from '@/features/modals/schema';

import { useAuthStore } from '@/stores/userStore';

import { Button } from '@/components/ui/button';
import {
  Dialog, DialogContent, DialogHeader, DialogOverlay,
  DialogTitle,
} from '@/components/ui/dialog';

import { useToast } from '@/components/ui/use-toast';

import { ScrollArea } from '../ui/scroll-area';

interface RegistrationPopupProps {
  isOpen: boolean;
  // eslint-disable-next-line react/require-default-props
  isGame?: boolean;
  // eslint-disable-next-line react/require-default-props
  account?: any;
}

const RegistrationPopup = ({ isOpen, isGame, account }: RegistrationPopupProps) => {
  const [isClicked, setIsClicked] = useState(false);

  const router = useRouter();
  const pathname = usePathname();
  const { toast } = useToast();
  const { setAccount, typeBonusSelected } = useAuthStore();
  const [imageToShow, setImageBanner] = useState<string>('/img/modal/registreProfile.png');

  useEffect(() => {
    const imageBanner: Record<string, string> = {
      casino: "bg-[url('/img/modal/casino.jpg')]",
      sports: "bg-[url('/img/modal/sports.jpg')]",
      none: "bg-[url('/img/modal/registreProfile.png')]",
    };
    setImageBanner(imageBanner[typeBonusSelected || 'none']);
  }, [typeBonusSelected]);
  // eslint-disable-next-line no-console
  console.log(imageToShow);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      secondname: '',
      lastname: '',
      country: 'hn',
      clubFavorite: '',
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      const response = await fetch('/api/dashboard/identity', {
        method: 'POST',
        body: JSON.stringify(values),
      });
      const res = await response.json();

      if (!res.success) {
        if (res.isFullProfile) {
          setAccount({
            ...account,
            fullProfile: true,
          });
        }
        toast({
          title: messages.titleToastError,
          description: messages.descriptionToastError,
          variant: 'destructive',
        });
      } else {
        if ((window as any).xtremepush) {
          (window as any).xtremepush('set', {
            nombres: values.name,
            apellidos: values.lastname,
            fecha_nacimiento: values.birthdate.toISOString().split('T')[0],
            pais: values.country,
            club_favorito: values.clubFavorite,
          });
        }
        setAccount({
          ...account,
          name: `${values.name} ${values.secondname}`,
          lastName: values.lastname,
          fullProfile: true,
        });
        toast({
          title: messages.titleToastSucces,
          description: messages.descriptionToastSucces,
          className: AppStyles.TOAST_SECONDARY,
        });

        router.push(pathname);
      }
    } catch (error) {
      toast({
        title: messages.titleToastError,
        description: messages.descriptionToastError,
        variant: 'destructive',
      });
    }
  }

  return (
    <div>
      <Dialog defaultOpen={!isOpen} modal>
        <DialogOverlay className="bg-black/10">
          <DialogContent
            className="bg-sivarbet-background border-none m-0 p-0 max-md:h-full rounded-lg"
            onOpenAutoFocus={(e) => { return e.preventDefault(); }}
          >
            <DialogHeader>
              <DialogTitle className={`rounded-t-lg self-stretch h-[250px] flex flex-row items-start justify-start py-[33px] px-[17px] box-border relative gap-[5px] ${imageToShow} bg-cover bg-no-repeat bg-[top]`} />
            </DialogHeader>
            <ScrollArea className="h-full flex flex-col items-center justify-center gap-3">
              {
                isClicked ? (
                  <FormRegisterPopUp action={onSubmit} form={form} account={account} />
                ) : (
                  <div className="flex flex-col items-center justify-center mx-5 my-3 max-md:h-full flex-wrap content-stretch">
                    <span className="text-xl font-bold text-sivarbet-text mb-3 text-center max-md:h-9">
                      {!isGame ? messages.completeForm : messages.completeFormGame}
                    </span>
                    <p className="text-base text-center text-sivarbet-secondary-text mb-3 max-md:px-6 max-md:h-full max-md:mb-0">
                      {!isGame ? messages.thanksRegister : messages.thanksRegisterGame}
                      <br />
                      <br />
                      {!isGame ? messages.inviteComplete : ''}
                    </p>
                    <Button className="bg-sivarbet-primary text-sivarbet-text hover:bg-sivarbet-primary-hover max-md:w-1/2 max-md:place-self-center max-md:mb-2 max-md:mt-2" onClick={() => { return setIsClicked(true); }}>
                      {!isGame ? messages.completeButton : messages.completeButtonGame}
                    </Button>
                  </div>
                )
              }
            </ScrollArea>
          </DialogContent>
        </DialogOverlay>
      </Dialog>
    </div>
  );
};

export default RegistrationPopup;
