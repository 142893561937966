import React, { useEffect, useState } from 'react';

import { motion } from 'framer-motion';

import SmallPopup from '@/features/Bonus/SmallPopup';
import { useAuthStore } from '@/stores/userStore';

const GiftBoxAnimation = () => {
  const [showModal, setShowModal] = useState(false);
  const [animationFinished, setAnimationFinished] = useState(false);
  const { account } = useAuthStore();
  const bonuses = account?.bonuses;
  const flattedBonuses = bonuses ? [...(bonuses.sport || []), ...(bonuses.spins || [])] : [];

  const colorTape = { all: '#5CC430', shadow: '#039C30' };
  const colorBox = { all: '#E63136', shadow: '#CC1325' };

  const checkBonusesActive = () => {
    const isSportOnHold = bonuses?.sport.some((bonusSport: any) => { return bonusSport?.status === 'Active'; });
    const isSpinStatus2 = bonuses?.spins.some((bonusSpin: any) => { return bonusSpin?.status === 2; });
    return isSportOnHold || isSpinStatus2;
  };

  const winningAnimationInProgress = {
    body: { y: 140 },
    head: { y: 100 },
    coin: {
      rotateY: 360, opacity: 1, scale: 1, y: -150,
    },
    confetti: { scale: 2 },
    stars: { scale: 1, opacity: 1 },
  };

  const winningAnimationFinished = {
    body: { y: 70 },
    head: { y: 300 },
    stars: { scale: 0, opacity: 0 },
    coin: { opacity: 0, scale: 0 },
    confetti: { scale: 0 },
  };

  const onHoldAnimation = {
    body: {
      y: 70,
      rotate: [-2, 2, -2],
      transition: {
        repeat: Infinity,
        repeatType: 'mirror',
        duration: 0.1,
      },
    },
    head: {
      y: 300,
      rotate: [-2, 2, -2],
      transition: {
        repeat: Infinity,
        repeatType: 'mirror',
        duration: 0.1,
      },
    },
    stars: { scale: 0, opacity: 0 },
    coin: { opacity: 0, scale: 0 },
    confetti: { scale: 1 },
  };

  const activeAnimation = checkBonusesActive() ? winningAnimationInProgress : onHoldAnimation;
  useEffect(() => {
    const startAnimation = setTimeout(() => {
      setAnimationFinished(true);
    }, 6000);

    return () => { return clearTimeout(startAnimation); };
  }, []);

  if (!flattedBonuses?.length) {
    return null;
  }
  return (
    <>
      {/* <FreeSpindModal isOpen={showModal} onClose={() => { return setShowModal(!showModal); }} data={data} /> */}
      <SmallPopup isVisible={showModal} setIsVisible={setShowModal} />
      <div onClick={() => { return setShowModal(true); }}>

        <motion.svg width="4rem" height="4rem" className="w-[3.3rem] h-[3.3rem] md:w-[4rem] md:h-[4rem]" viewBox="0 0 638 658" fill="none" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient id="paint0_linear_330_72" x1="392.778" y1="301" x2="392.778" y2="394.557" gradientUnits="userSpaceOnUse">
              <stop stopColor="#D2B30C" />
              <stop offset="1" stopColor="#BA9824" />
            </linearGradient>
            <linearGradient id="paint1_linear_330_72" x1="393.558" y1="310.356" x2="393.558" y2="386.76" gradientUnits="userSpaceOnUse">
              <stop stopColor="#BA9824" />
              <stop offset="1" stopColor="#FAC05E" />
            </linearGradient>
          </defs>

          <motion.g
            id="body"
            initial={{ y: 70, translateY: '-15rem' }}
            animate={animationFinished ? winningAnimationFinished.body : activeAnimation.body}
            transition={{
              type: 'spring', stiffness: 300, damping: 20,
            }}
            style={{ zIndex: 1, position: 'relative' }}
          >
            <path d="M260 641.5V442.5H523V637C523 650.5 515.5 657.5 509.5 657.5H284.5C278 657.5 260 657.5 260 641.5Z" fill={colorBox.all} />
            <path d="M260 442.5V524H523V442.5H260Z" fill={colorBox.shadow} />
            <path d="M364 524V442H418V524H364Z" fill={colorTape.shadow} />
            <path d="M364 657.5V524H418V657.5H364Z" fill={colorTape.all} />
          </motion.g>

          <motion.g
            id="head"
            initial={{ y: 300, translateY: '-15rem' }}
            animate={animationFinished ? { y: 300 } : activeAnimation.head}
            transition={{
              type: 'spring', stiffness: 300, damping: 20,
            }}
            style={{ zIndex: 1, position: 'relative' }}
          >
            <path d="M258 135.5V202.5H526V134C526 128.5 516.5 122 507 127.5C497.5 133 412.5 189.5 412.5 187.5C413.5 184.5 411 177.5 390.5 178C368.5 178 370.5 187.5 370.5 189.5C369 188.5 280.5 131 275 127.5C269.5 124 258 125.5 258 135.5Z" fill={colorTape.all} />
            <rect x="229" y="202" width="322" height="55" rx="27.5" fill={colorBox.all} />
            <rect x="364" y="202" width="54" height="55" fill={colorTape.all} />
          </motion.g>

          <motion.g
            id="confetti"
            initial={{ scale: 0 }}
            animate={animationFinished ? winningAnimationFinished.confetti : activeAnimation.confetti}
            transition={{ duration: 3.8, ease: 'linear', repeat: animationFinished ? 0 : Infinity }}
            style={{ zIndex: -1, position: 'relative' }}
          >
            <rect x="153" y="547.81" width="23.7006" height="9.37798" rx="4.68899" transform="rotate(-32.717 153 547.81)" fill="#C338B3" />
            <rect x="217" y="439.81" width="23.7006" height="9.37798" rx="4.68899" transform="rotate(-32.717 217 439.81)" fill="#C338B3" />
            <rect x="532" y="275.81" width="23.7006" height="9.37798" rx="4.68899" transform="rotate(-32.717 532 275.81)" fill="#C338B3" />
            <rect x="553.059" y="357.938" width="23.7006" height="9.37798" rx="4.68899" transform="rotate(-1.88043 553.059 357.938)" fill="#01CC69" />
            <rect x="231.074" y="251.305" width="23.7006" height="9.37798" rx="4.68899" transform="rotate(31.7096 231.074 251.305)" fill="#01CC69" />
            <rect x="109.611" y="258.13" width="23.7006" height="9.37798" rx="4.68899" transform="rotate(23.8955 109.611 258.13)" fill="#01CC69" />
            <rect x="200.35" y="135.775" width="23.7006" height="9.37798" rx="4.68899" transform="rotate(56.6758 200.35 135.775)" fill="#FFB900" />
            <rect x="667.473" y="272.591" width="23.7006" height="9.37798" rx="4.68899" transform="rotate(156.365 667.473 272.591)" fill="#FFB900" />
            <rect x="615.279" y="561.413" width="23.7006" height="9.37798" rx="4.68899" transform="rotate(-144.436 615.279 561.413)" fill="#FFB900" />
            <rect x="680.699" y="422.656" width="23.7006" height="9.37798" rx="4.68899" transform="rotate(-179.326 680.699 422.656)" fill="#0177D9" />
            <rect x="223.699" y="352.656" width="23.7006" height="9.37798" rx="4.68899" transform="rotate(-179.326 223.699 352.656)" fill="#0177D9" />
            <rect x="117.699" y="417.656" width="23.7006" height="9.37798" rx="4.68899" transform="rotate(-179.326 117.699 417.656)" fill="#0177D9" />
            <rect x="548.014" y="459.068" width="23.7006" height="9.37798" rx="4.68899" transform="rotate(-154.319 548.014 459.068)" fill="#FD4341" />
            <rect x="581.285" y="146.384" width="23.7006" height="9.37798" rx="4.68899" transform="rotate(125.039 581.285 146.384)" fill="#FD4341" />
          </motion.g>

          <motion.g
            id="stars"
            initial={{ scale: 1, opacity: 0 }}
            animate={animationFinished ? winningAnimationFinished.stars : activeAnimation.stars}
            transition={{
              staggerChildren: 0.1, duration: 1, ease: 'anticipate', repeat: animationFinished ? 0 : Infinity,
            }}
          >
            <path d="M713.5 339.5C712 349 713.5 366 690.5 383.5C712.5 397 712 423.5 713.5 430C717.5 396.5 729 389 736.5 383.5C727 376 717 366 713.5 339.5Z" fill="#FFBA01" />
            <path d="M622 296C621.609 298.519 622 303.028 616 307.669C621.739 311.249 621.609 318.276 622 320C623.043 311.116 626.043 309.127 628 307.669C625.522 305.68 622.913 303.028 622 296Z" fill="#FFBA01" />
            <path d="M729.5 209C728.946 212.569 729.5 218.956 721 225.53C729.13 230.602 728.946 240.558 729.5 243C730.978 230.414 735.228 227.597 738 225.53C734.489 222.713 730.793 218.956 729.5 209Z" fill="#FFBA01" />
            <path d="M675.5 574C674.75 578.829 675.5 587.47 664 596.365C675 603.227 674.75 616.696 675.5 620C677.5 602.972 683.25 599.16 687 596.365C682.25 592.552 677.25 587.47 675.5 574Z" fill="#FFBA01" />
            <path d="M592.5 23C591.75 27.8287 592.5 36.4696 581 45.3646C592 52.2265 591.75 65.6961 592.5 69C594.5 51.9724 600.25 48.1602 604 45.3646C599.25 41.5524 594.25 36.4696 592.5 23Z" fill="#FFBA01" />
            <path d="M229.5 0C228.75 4.82868 229.5 13.4696 218 22.3646C229 29.2265 228.75 42.6961 229.5 46C231.5 28.9724 237.25 25.1602 241 22.3646C236.25 18.5524 231.25 13.4696 229.5 0Z" fill="#FFBA01" />
            <path d="M104.5 217C103.163 225.608 104.5 241.011 84 256.867C103.609 269.099 103.163 293.11 104.5 299C108.065 268.646 118.315 261.851 125 256.867C116.533 250.072 107.62 241.011 104.5 217Z" fill="#FFBA01" />
            <path d="M14.5 145C13.5543 151.088 14.5 161.983 0 173.199C13.8696 181.851 13.5543 198.834 14.5 203C17.0217 181.53 24.2717 176.724 29 173.199C23.0109 168.392 16.7065 161.983 14.5 145Z" fill="#FFBA01" />
            <path d="M180 385C179.283 389.619 180 397.884 169 406.392C179.522 412.956 179.283 425.84 180 429C181.913 412.713 187.413 409.066 191 406.392C186.457 402.746 181.674 397.884 180 385Z" fill="#FFBA01" />
            <path d="M85 560C84.2826 564.619 85 572.884 74 581.392C84.5217 587.956 84.2826 600.84 85 604C86.913 587.713 92.413 584.066 96 581.392C91.4565 577.746 86.6739 572.884 85 560Z" fill="#FFBA01" />
          </motion.g>

          <motion.g
            id="coin"
            initial={{ opacity: 1, scale: 0, y: -90 }}
            animate={!animationFinished ? activeAnimation.coin : winningAnimationFinished.coin}
            transition={{
              type: 'spring', stiffness: 300, damping: 20, repeat: animationFinished ? 0 : Infinity,
            }}
          >
            <circle cx="392.778" cy="347.778" r="46.7783" fill="url(#paint0_linear_330_72)" />
            <circle cx="393.558" cy="348.558" r="38.2023" fill="url(#paint1_linear_330_72)" />
            <path d="M385 360.487C386.308 362.138 388.104 363.306 390.116 363.813C394.664 365.024 399.14 362.896 400.114 359.065C401.088 355.229 397.568 351.048 393.022 349.835C388.476 348.622 384.954 344.443 385.928 340.61C386.9 336.776 391.376 334.651 395.924 335.861C397.888 336.346 399.664 337.491 401 339.134M393.242 364.152V368M393.242 331V335.522" stroke="#EFDA64" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
          </motion.g>
        </motion.svg>
      </div>
    </>
  );
};

export default GiftBoxAnimation;
