interface Props {
  bankDetails: any;
  selectedPaymentMethod: string;
}

const CashContent = ({ bankDetails, selectedPaymentMethod }: Props) => {
  return (
    <div className="mt-4 flex flex-col items-center justify-stretch text-center leading-6">
      {bankDetails[selectedPaymentMethod].instructions}
    </div>
  );
};

export default CashContent;
