import React from 'react';

import { OptionsSidebar } from '@/interfaces/core';

import LinkItem from '@/components/items/LinkItem';
import { Separator } from '@/components/ui/separator';
import { TabsContent } from '@/components/ui/tabs';

const CasinoTab = ({ isOpen, arrOptions }: { isOpen: boolean, arrOptions: OptionsSidebar[] }) => {
  return (
    <TabsContent value="casino" className="flex flex-col bg-sivarbet-background rounded-xl">
      {isOpen ? (
        <>
          <span className="px-4 py-3 text-base font-medium">Casino</span>
          <Separator className="bg-sivarbet-border border-sivarbet-border border-[1px]" />
          {arrOptions.filter((link) => { return link.type === 'casino'; }).map((link) => {
            return (
              <LinkItem key={link.title} link={link} isCollapsed={!isOpen} />
            );
          })}
        </>
      ) : (
        <div className="flex flex-col items-center">
          {arrOptions.filter((link) => { return link.type === 'casino'; }).map((link) => {
            return (
              <LinkItem key={link.title} link={link} isCollapsed={!isOpen} />
            );
          })}
        </div>
      )}
    </TabsContent>
  );
};

export default CasinoTab;
