/* eslint-disable */
import React from 'react';

interface IconGiftProps {
  width: number;
  height: number;
  className?: string;
}
const IconGift: React.FC<IconGiftProps> = ({ width, height, className }) => {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_31_2)">
        <path d="M32 36H4C2.9 36 2 35.1 2 34V14H34V34C34 35.1 33.1 36 32 36Z" fill="url(#paint0_linear_31_2)" />
        <path d="M36 14H0V8.00001C0 6.9 0.899998 6 2.00001 6H34C35.1 6 36 6.9 36 8.00001V14Z" fill="url(#paint1_linear_31_2)" />
        <path d="M16 14H20V36H16V14ZM26 0H22L16 6.00002H20L26 0Z" fill="url(#paint2_linear_31_2)" />
        <path d="M14 0H10L16 6.00002V14H20V6.00002L14 0Z" fill="url(#paint3_linear_31_2)" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_31_2" x1="18.5875" y1="15.5745" x2="18" y2="31.9615" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5BA51C" />
          <stop offset="1" stopColor="#8BDB46" />
        </linearGradient>
        <linearGradient id="paint1_linear_31_2" x1="17.5478" y1="14.8777" x2="17.1346" y2="10.0384" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5BA51C" />
          <stop offset="1" stopColor="#8BDB46" />
        </linearGradient>
        <linearGradient id="paint2_linear_31_2" x1="18.5769" y1="14.5962" x2="17.5486" y2="38.373" gradientUnits="userSpaceOnUse">
          <stop stopColor="#29ABE2" stopOpacity="0.990991" />
          <stop offset="1" stopColor="#1D2A51" />
        </linearGradient>
        <linearGradient id="paint3_linear_31_2" x1="20.5856" y1="13.2358" x2="9.42878" y2="-0.52848" gradientUnits="userSpaceOnUse">
          <stop stopColor="#29ABE2" />
          <stop offset="1" stopColor="#1D2A51" />
        </linearGradient>
        <clipPath id="clip0_31_2">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconGift;
