'use client';

import React, { useState } from 'react';

import Link from 'next/link';

import { cn } from '@/lib/utils';

import { NavProps } from '@/utils/types/navbartypes';

import { buttonVariants } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@/components/ui/dropdown-menu';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

interface DropdownItemProps {
  link: NavProps['links'][number];
  isCollapsed: boolean;
}

const DropdownItem = ({ link, isCollapsed }: DropdownItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <DropdownMenu open={isOpen}>
          <DropdownMenuTrigger
            asChild
            className="p-0"
            onMouseEnter={() => {
              return setIsOpen(true);
            }}
            onMouseLeave={() => {
              return setIsOpen(false);
            }}
          >
            <div
              data-testid="dropdown-trigger"
              className={cn(
                buttonVariants({
                  size: isCollapsed ? 'icon' : 'sm',
                }),
                'p-0 text-base bg-sivarbet-background hover:bg-sivarbet-primary dark:bg-muted dark:text-sivarbet-text dark:hover:bg-muted dark:hover:bg-sivarbet-primary data-[state=active]:bg-sivarbet-primary rounded-xl',
                isCollapsed ? 'h-9 w-full hover:bg-sivarbet-primary flex items-center justify-center' : 'justify-start hover:bg-sivarbet-primary',
              )}
            >
              {isCollapsed ? (
                <TooltipTrigger asChild>
                  <span className="p-4 flex items-baseline gap-3">
                    {typeof link.icon === 'string' ? (
                      <span className={`h-4 w-4 'mr-2' icon icon-${link.icon} 'text-base'`} />
                    ) : (
                      link.icon
                    )}
                  </span>
                </TooltipTrigger>
              ) : (
                <span className="p-4 flex items-baseline gap-3">
                  {typeof link.icon === 'string' ? (
                    <span className={`h-4 w-4  icon icon-${link.icon} 'text-base'`} />
                  ) : (
                    <span>{link.icon}</span>
                  )}
                  <span className="text-base">
                    {link.title}
                  </span>
                </span>
              )}

              {!isCollapsed && link.label && (
                <span className={cn('ml-auto', 'text-background dark:text-sivarbet-text')}>
                  {link.label}
                </span>
              )}
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            onMouseEnter={() => {
              return setIsOpen(true);
            }}
            onMouseLeave={() => {
              return setIsOpen(false);
            }}
            side="right"
            className="bg-sivarbet-background text-sivarbet-text border-none"
          >
            {link.list
                && link.list.map((item) => {
                  return (
                    <DropdownMenuItem key={item.name} className="hover:bg-sivarbet-primary hover:text-sivarbet-border">
                      <Link href={item.link}>{item.name}</Link>
                    </DropdownMenuItem>
                  );
                })}
          </DropdownMenuContent>
        </DropdownMenu>
        <TooltipTrigger asChild>
          <div className="sr-only">{link.title}</div>
        </TooltipTrigger>
        {!isCollapsed && (
          <TooltipContent side="right" className="flex items-center gap-4">
            {link.title}
            {link.label && (
              <span className="ml-auto text-muted-foreground">
                {link.label}
              </span>
            )}
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
};

export default DropdownItem;
