'use client';

import React from 'react';

import Image from 'next/image';

import { motion } from 'framer-motion';

import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { useToast } from '@/components/ui/use-toast';

import { IconBall, IconDice, IconTrash } from '@/public/img/marketing/promotions';

interface BonusSelectionProps {
  welcomeBonus: string;
  setWelcomeBonus: (value: string) => void;
  handleBonusSelection: () => void;
}

const BonusSelection: React.FC<BonusSelectionProps> = ({ welcomeBonus, setWelcomeBonus, handleBonusSelection }) => {
  const { toast } = useToast();

  const diceVariants = {
    initial: { rotate: 0 },
    animate: {
      rotate: [0, 360, 720, 1080, 1440, 1800, 1440, 1080, 720, 360, 0],
      transition: {
        duration: 9,
        ease: [0.83, 0, 0.17, 1],
        repeat: Infinity,
        repeatType: 'loop' as const,
      },
    },
  };

  const footballVariants = {
    initial: { y: 0 },
    animate: {
      y: [0, -7, 10],
      transition: {
        duration: 1,
        repeat: Infinity,
        repeatType: 'loop' as const,
      },
    },
  };

  //   const ticketVariants = {
  //     initial: { x: 0, color: '#FFD700' },
  //     animate: {
  //       x: [0, 10, 0],
  //       color: ['#FFD700', '#C0C0C0', '#FFD700'],
  //       transition: {
  //         duration: 1.5,
  //         repeat: Infinity,
  //         repeatType: 'loop' as const,
  //       },
  //     },
  //   };

  const handleBonusClick = () => {
    if (!welcomeBonus) {
      toast({ title: 'Por favor, selecciona un bono de bienvenida', variant: 'destructive' });
    } else {
      handleBonusSelection();
    }
  };

  return (
    <div className="p-6 space-y-4 mt-4">
      <h2 className="text-2xl font-bold text-center mt-0 mb-8">Escoge tu regalo de bienvenida</h2>
      <RadioGroup value={welcomeBonus} onValueChange={setWelcomeBonus} className="space-y-2">
        <div className="flex items-center justify-between p-4 rounded-lg border border-sivarbet-border h-[60px]">
          <div className="flex items-center space-x-2">
            <motion.div variants={diceVariants} initial="initial" animate="animate">
              <Image src={IconDice} alt="IconBall" width={20} height={20} />
            </motion.div>
          </div>
          <Label htmlFor="casino" className="flex-grow ml-2">
            <span className="text-xs text-gray-600 block">Casino</span>
            100 Giros Gratis!
          </Label>
          <RadioGroupItem value="casino" id="casino" className="border-sivarbet-primary text-sivarbet-primary" />
        </div>

        <div className="flex items-center justify-between p-4 rounded-lg border border-sivarbet-border h-[60px]">
          <div className="flex items-center space-x-2">
            <motion.div variants={footballVariants} initial="initial" animate="animate">
              <Image src={IconBall} alt="IconBall" width={20} height={20} />
            </motion.div>
          </div>
          <Label htmlFor="sports" className="flex-grow ml-2">
            <span className="text-xs text-gray-600 block">Deportivas</span>
            Apuesta Gratis de L100!
          </Label>
          <RadioGroupItem value="sports" id="sports" className="border-sivarbet-primary text-sivarbet-primary" />
        </div>

        {/* <div className="flex items-center justify-between p-4 rounded-lg border border-sivarbet-border h-[60px]">
          <div className="flex items-center space-x-2">
            <motion.div variants={ticketVariants} initial="initial" animate="animate">
              <IoTicket size={30} />
            </motion.div>
          </div>
          <Label htmlFor="promocode" className="flex-grow ml-2">Tengo un código promocional</Label>
          <RadioGroupItem value="promocode" id="promocode" className="border-sivarbet-primary text-sivarbet-primary" />
        </div> */}

        <div className="flex items-center justify-between p-4 rounded-lg border border-sivarbet-border h-[60px] mb-4">
          <div className="flex items-center space-x-2">
            <Image src={IconTrash} alt="IconTrash" width={20} height={20} />
          </div>
          <Label htmlFor="none" className="flex-grow ml-2">No quiero un bono de bienvenida</Label>
          <RadioGroupItem value="none" id="none" className="border-sivarbet-primary text-sivarbet-primary" />
        </div>
      </RadioGroup>
      <Button onClick={handleBonusClick} className="w-full font-bold bg-sivarbet-primary text-sivarbet-background mt-4 hover:!bg-sivarbet-primary-hover">
        Reclama tu regalo!
      </Button>
    </div>
  );
};

export default BonusSelection;
