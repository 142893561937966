import { useRouter } from 'next/navigation';

import { IoWalletOutline } from 'react-icons/io5';

import { ContentDeposit } from '@/features/deposit/components';

import { useAuthStore } from '@/stores/userStore';
import { userUIStore } from '@/stores/userUIStore';

import { Button } from '@/components/ui/button';
import {
  Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger,
} from '@/components/ui/sheet';

import { ScrollArea } from '../ui/scroll-area';

const DepositSheet = () => {
  const {
    isDepositSheetOpen, toggleDepositSheet, isOpenDeposit, setOpenDeposit,
  } = userUIStore();
  const { account } = useAuthStore();
  const router = useRouter();
  return (
    <Sheet
      open={isDepositSheetOpen}
      modal
      onOpenChange={toggleDepositSheet}
    >
      <SheetTrigger onClick={() => { return setOpenDeposit(false); }}>
        <Button
          asChild
          className="bg-[radial-gradient(circle,_rgb(149,251,63)_60%,_rgb(70,252,109)_100%)] text-sivarbet-border rounded-lg mx-4 py-0 max-md:!h-[32px] max-md:px-[8px] max-md:py-[2px] hover:bg-sivarbet-primary/50"
          onClick={() => {
            if (account?.contingencies?.deposits) {
              router.push('/?cont=4');
            }
          }}
        >
          <div className="flex items-center justify-center">
            <span className="icon icon-wallet text-4xl max-md:text-3xl" />
          </div>
        </Button>

      </SheetTrigger>
      <SheetContent side="bottom" className={`px-1 py-3 w-full mx-auto h-1/2 border-sivarbet-border bg-sivarbet-background text-sivarbet-text rounded-lg shadow-2xl shadow-black ${isOpenDeposit ? 'h-full' : ''}`}>
        <ScrollArea className="w-full h-full">
          <SheetHeader>
            <SheetTitle className="flex items-center gap-2 text-start pb-4 font-medium text-lg text-sivarbet-text">
              <IoWalletOutline className="text-xl" />
              Deposito
            </SheetTitle>
          </SheetHeader>
          <ContentDeposit />
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
};

export default DepositSheet;
