/* eslint-disable react/require-default-props */

'use client';

import React, { useState, useEffect, memo } from 'react';

import SlotCounter from 'react-slot-counter';

import { core } from '@/localization';

import { useAuthStore } from '@/stores/userStore';

import { moneyFormat } from '@/utils/core';

import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuPortal,
} from '@/components/ui/dropdown-menu';

type Props = {
  data: any,
  // available_balance: number,
  // deposit_balance: number,
  // isOpen?: boolean,
};

enum BalanceDescription {
  SALDO_RETIRO = 'SALDO RETIRO',
  SALDO_RECARGA = 'SALDO RECARGA',
  GIROS_GRATIS = 'GIROS GRATIS Y/O FREECASINO',
  BONOS_DEPORTIVOS = 'BONOS DEPORTIVOS',
}
const SelectBalance = ({ data }: Props) => {
  const { balance } = useAuthStore();
  const [prevAmount, setPrevAmount] = useState<number>(0);

  useEffect(() => {
    if (data.length > 0) setPrevAmount(data[0]?.amount ?? 0);
  }, [data]);

  const renderPopover = (value: {
    type: string;
    amount: number;
    description: string;
    color?: string;
  }) => {
    let popoverBodyContent;

    if (value.description === BalanceDescription.SALDO_RETIRO) {
      popoverBodyContent = (
        <div className="text-sm text-wrap">
          <div>
            Es el saldo que podés retirar. Representa el dinero real que tienes a disposición para jugar o retirar.
          </div>
        </div>
      );
    } else if (value.description === BalanceDescription.SALDO_RECARGA) {
      popoverBodyContent = (
        <div className="text-sm text-wrap">
          Saldo que has depositado. Es necesario jugarlo y ganarlo para poder retirar.
          <br />

        </div>
      );
    } else if (value.description === BalanceDescription.GIROS_GRATIS) {
      popoverBodyContent = (
        <div className="text-sm text-wrap">
          <div>
            Jugadas gratuitas para usar en el casino slot. Te ofrece la posibilidad de ganar dinero real o en forma de bono.
            <br />

          </div>
        </div>
      );
    } else {
      popoverBodyContent = (
        <div className="text-sm text-wrap">
          <div>Bonos Deportivos sin liberar. este saldo se puede usar en apuestas deportivas </div>
        </div>
      );
    }

    return (
      <div className="bg-sivarbet-secondary-bg p-4 rounded-md shadow-lg">
        <h3 className="font-bold">{value.description}</h3>
        {popoverBodyContent}
      </div>
    );
  };

  if (data.length === 0) return null;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger data-testid="select-button" className="p-2 py-3 px-3 flex items-center rounded-lg bg-sivarbet-secondary-bg max-md:p-1 max-md:py-2 max-md:px-2">
        <span className="text-sivarbet-secondary icon icon-coins_line max-md:text-xs max-sm:text-2xs" />
        <span className="text-sivarbet-secondary pl-2 flex items-center max-md:pl-1 max-md:text-sm">
          <SlotCounter
            startValue={moneyFormat(prevAmount)}
            value={moneyFormat(balance[0]?.amount.toFixed(2) ?? '0')}
            startValueOnce
            duration={2}
            animateUnchanged
            autoAnimationStart
          />
        </span>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="select-balance-menu bg-sivarbet-background border-sivarbet-border text-sivarbet-text ml-8 w-full">
        <DropdownMenuLabel className="text-lg flex flex-row items-center gap-1 pb-4">
          <span className="fill-sivarbet-secondary icon icon-coins_line" />
          Saldos
        </DropdownMenuLabel>
        <DropdownMenuLabel className="text-sivarbet-secondary font-bold text-xl pb-5">
          {`${core.totalBalance}`}
          <span className="font-normal">
            {` ${moneyFormat(balance[0]?.amount.toFixed(2) ?? '0')}`}
          </span>
        </DropdownMenuLabel>
        <div className="text-nowrap">
          {data.filter((_:any, index:number) => { return index > 0; }).map((item:any) => {
            return (
              <React.Fragment key={item.description}>
                <DropdownMenuItem
                  className="hover:bg-sivarbet-primary-hover focus:bg-sivarbet-primary-hover"
                  // onClick={() => { return setSelected(item); }}
                >
                  <div className="flex justify-between items-center gap-x-28 w-full text-lg">
                    <span>{`L ${moneyFormat(item.amount)}`}</span>
                    <div className="flex justify-end items-center">
                      <span>{item.description}</span>
                      <DropdownMenuSub>
                        <DropdownMenuSubTrigger data-testid="popover" className="end-full hover:bg-transparent focus:bg-transparent hover:text-sivarbet-primary data-[state=open]:bg-transparent">
                          <span className="fill-white icon icon-info" />
                        </DropdownMenuSubTrigger>
                        <DropdownMenuPortal>
                          <DropdownMenuSubContent className="w-1/2 bg-sivarbet-secondary-bg text-sivarbet-primary border-sivarbet-border">
                            {renderPopover(item)}
                          </DropdownMenuSubContent>
                        </DropdownMenuPortal>
                      </DropdownMenuSub>
                    </div>
                  </div>
                </DropdownMenuItem>
                <DropdownMenuSeparator className="bg-sivarbet-border" />
              </React.Fragment>
            );
          })}
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default memo(SelectBalance);
