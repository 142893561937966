import React from 'react';

import Image from 'next/image';

import Logo from '@/public/img/logo/hondubetlogo.png';

const LoadingSpinner = () => {
  return (
    <div role="status">
      <div className="flex items-center justify-center h-screen z-50 fixed top-0 left-0 w-full bg-gray/50">
        <Image src={Logo} alt="Loading..." className="spinner-image" width={250} height={90} priority />
      </div>
    </div>
  );
};

export default LoadingSpinner;
