'use client';

import { useEffect, useState } from 'react';

import Link from 'next/link';

import { useSession } from 'next-auth/react';
import { CiCircleMinus, CiCirclePlus } from 'react-icons/ci';

import { useAuthStore } from '@/stores/userStore';

import { decrypt } from '@/utils/core';

import LoadingSpinner from '@/components/common/LoadingSpinner';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';

import { Separator } from '@/components/ui/separator';

import { useToast } from '@/components/ui/use-toast';

import { getNicoCallback } from '../services';

const NicoPay = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState('');
  const [isOtherValue, setIsOtherValue] = useState(false);
  const [showPromoInput, setShowPromoInput] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [isVerify, setIsVerify] = useState(false);
  const [error, setError] = useState('');
  const { toast } = useToast();
  const { data: session } = useSession();
  const { account } = useAuthStore();

  const minDeposit = 200;
  const maxDeposit = 60000;
  useEffect(() => {
    if (account.kycLevel === 1 || account.kycLevel === 2) {
      setIsVerify(true);
    } else {
      toast({
        title: 'Verificación requerida',
        description: 'Necesitas completar la verificación de identidad para depositar dinero',
        variant: 'destructive',
      });
    }
  }, [account, toast]);

  const handleAmountClick = (amount: number) => {
    setSelectedAmount(amount.toFixed(2)); // Ensure the value has two decimal places when selected
    setIsOtherValue(false);
    setError('');
  };

  const handleOtherValueClick = () => {
    setSelectedAmount('');
    setIsOtherValue(true);
  };

  const togglePromoCode = () => {
    setShowPromoInput(!showPromoInput);
  };

  const handleDeposit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      if (session && session?.user?.data && session?.user?.tag) {
        const userId = await decrypt(session?.user?.data, session?.user?.tag);

        const json = {
          orderName: 'Deposito',
          orderDescription: 'Deposito de dinero',
          amount: Number(selectedAmount),
          successUrl: `${process.env.NEXT_PUBLIC_RESPONSE_URL}/?deposit=complete`,
          cancelUrl: `${process.env.NEXT_PUBLIC_RESPONSE_URL}/?deposit=cancel`,
          metadata: [{
            name: 'userId',
            value: userId,
          }],
        };
        const { response } = await getNicoCallback(json);
        if (response.paymentLinkUrl) {
          setIsLoading(false);

          window.location.href = response.paymentLinkUrl;
          toast({
            title: 'Deposito iniciado',
            description: 'Redirigiendo al sitio de N1co para completar el deposito',
          });
        } else {
          setIsLoading(false);
          toast({
            title: 'Error',
            description: 'Error iniciando pago',
            variant: 'destructive',
          });
        }
      }
    } catch (DepositError: any) {
      setIsLoading(false);
      toast({
        title: 'Error',
        description: DepositError.message,
        variant: 'destructive',
      });
    }
  };

  const handleAmountChange = (event: { target: { value: any } }) => {
    const { value } = event.target;
    // This regex ensures only numbers and a single dot are entered
    if (/^\d*\.?\d*$/.test(value)) {
      const numericValue = parseFloat(value);
      if (numericValue < minDeposit) {
        setError('El monto mínimo de depósito es L 200');
      } else if (numericValue > maxDeposit) {
        setError('El monto máximo de depósito es L 60.000');
      } else {
        setError('');
      }
      setSelectedAmount(value);
    }
  };

  const formatFinalAmount = () => {
    if (selectedAmount !== '') {
      setSelectedAmount(parseFloat(selectedAmount).toFixed(2));
    }
  };

  return (
    <div>
      <p className="text-xs text-end text-sivarbet-text ">Acreditación inmediata</p>
      <Separator className="my-2 bg-sivarbet-border" />
      <h3 className="text-lg font-semibold text-center">Completa tu deposito</h3>
      <Separator className="my-2 bg-sivarbet-border" />
      <div onClick={togglePromoCode} className="cursor-pointer flex justify-between">
        <span className="promo text-sivarbet-text">Código promocional</span>
        <Link href="/" className="flex gap-1 items-center text-sivarbet-primary">
          <span>
            {showPromoInput ? <CiCircleMinus /> : <CiCirclePlus />}
          </span>
          <span>Ingresa el código</span>
        </Link>
      </div>
      {showPromoInput && (
        <div className="my-4">
          <Input
            type="text"
            value={promoCode}
            onChange={(e) => { return setPromoCode(e.target.value); }}
            placeholder="Codigo"
            className="input-form-text"
          />
        </div>
      )}
      <ul className="flex items-stretch justify-between gap-4 my-4 w-full">
        {[200, 500, 1000].map((itm) => {
          return (
            <li
              key={itm}
              onClick={() => { return handleAmountClick(itm); }}
              className="cursor-pointer p-2 border-none bg-sivarbet-primary hover:bg-sivarbet-primary-hovers rounded w-full"
            >
              <h5 className="text-center">
                L
                {itm}
              </h5>
            </li>
          );
        })}
        <li onClick={handleOtherValueClick} className="cursor-pointer p-2 border-none bg-sivarbet-primary hover:bg-sivarbet-primary-hover rounded text-nowrap">
          <Link href="/">Otro valor</Link>
        </li>
      </ul>
      {isLoading && <LoadingSpinner />}
      <form onSubmit={handleDeposit}>
        <div className="mb-4">
          <Input
            type="number"
            id="dAmount"
            placeholder="Monto de deposito"
            value={selectedAmount}
            onChange={handleAmountChange}
            onBlur={formatFinalAmount}
            disabled={!isOtherValue}
            min="200"
            step="0.01"
            className="input-form-text"
          />
          {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
        </div>

        <Separator className="my-2 bg-sivarbet-border" />
        <div className="my-3">
          <div className="items-center flex justify-between">
            <span>Total</span>
            <span>
              L
              {selectedAmount ? parseFloat(selectedAmount).toFixed(2) : '0'}
            </span>
          </div>
        </div>
        <Separator className="my-2 bg-sivarbet-border" />
        <div className="btn-area">
          <Button type="submit" disabled={!isVerify || selectedAmount === '' || error !== ''} className="w-full p-2 bg-sivarbet-primary hover:bg-sivarbet-primary-hover text-white rounded">
            <span>Completar Deposito</span>
          </Button>
        </div>
        <p className="text-center text-sm mt-4 text-white">
          Serás redirigido al sitio de N1CO para completar tu depósito.
        </p>
      </form>
    </div>
  );
};

export default NicoPay;
