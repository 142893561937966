import { useState } from 'react';

import Image from 'next/image';
import { useRouter } from 'next/navigation';

import './Bonus.css';
import { useAuthStore } from '@/stores/userStore';

import { bannerBonus, coinsBonus, sportBonus } from '@/public/img/marketing/promotions';

export default function SmallPopup({ isVisible, setIsVisible }: { isVisible: boolean, setIsVisible: (isVisible: boolean) => void }) {
  const router = useRouter();
  const { account } = useAuthStore();
  const { bonuses } = account;
  const [show, setShow] = useState('casino');

  const checkBonusesActive = () => {
    const isSportOnHold = bonuses.sport.some((bonusSport: any) => { return bonusSport?.status === 'Active'; });
    const isSpinStatus2 = bonuses.spins.some((bonusSpin: any) => { return bonusSpin?.status === 2; });
    return isSportOnHold || isSpinStatus2;
  };

  const handleBonusClick = (path: string) => {
    router.push(path);
    setIsVisible(false);
  };
  const handleFreespinsClick = async (gameCode:string) => {
    const game = gameCode.split(':');
    const code = gameCode.split('-');
    const dataGame = code[0];
    const url = code[1] === 'USD' ? `/casino/play?provider=${game[0]}&gamecode=${dataGame}&currency=USD` : `/casino/play?provider=${game[0]}&gamecode=${dataGame}&currency=HNL`;
    router.push(url);
  };
  if (!isVisible) return null;

  return (

    <div
      className="absolute inset-0 w-[100vw] h-[100vh] bg-black/50 z-10"
      onClick={() => { return setIsVisible(false); }}
    >
      <div className="fixed top-[15vh] left-1/2 transform -translate-x-1/2   inset-bg-transparent flex items-center justify-center p-4" onClick={(e) => { return e.stopPropagation(); }}>
        <div className="relative bg-gradient-to-b from-sivarbet-border via-sivarbet-secondary-bg to-sivarbet-border p-6 rounded-lg shadow-lg  w-[90vw] xl:!w-[40vw] h-auto">
          <Image src={bannerBonus} alt="Promotion" width={400} height={100} className="absolute top-0 left-0 w-full h-[80px] object-cover" />
          <div className="relative cursor-pointer">
            <div className=" absolute clipR rounded-e-2 bg-gradient-to-r inset-y-1 from-sivarbet-border form-10% via-sivarbet-primary-hover via-30% to-sivarbet-primary w-28" />
            <div className=" absolute clipL rounded-s-2 end-0 bg-gradient-to-r inset-y-1 from-sivarbet-primary from-50% via-sivarbet-primary-hover via-10% to-sivarbet-border t0-5% w-28" />
            <div className="relative w-10/12 h-[40px] mx-auto bg-gradient-to-r from-sivarbet-primary via-sivarbet-primary-hover to-sivarbet-primary text-white text-center py-3 px-6 rounded-3xl font-bold text-3xl blur-1">
              <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-sm xl:text-xl w-full">
                {checkBonusesActive() ? 'Tienes bonos disponibles' : 'Tienes bonos por reclamar'}
              </p>
              <div className="absolute w-8 xl:w-20 end-0 h-[80%] inset-y-1 bg-gradient-to-r from-sivarbet-primary  via-sivarbet-primary-hover  to-sivarbet-border rounded-full blur-sm" />
              <div className="absolute w-8 xl:w-14 start-0 h-[80%] inset-y-1 bg-gradient-to-r from-sivarbet-border via-sivarbet-primary-hover to-sivarbet-primaryrounded-full blur-sm" />
            </div>
          </div>

          {/* ==================== Tabs ==================== */}
          <div className="flex items-center justify-evenly w-fit mt-8 md:mt-12  bg-gradient-to-b from-sivarbet-primary/15 to-transparent  rounded-sm">
            <div
              className={`${show !== 'casino' ? 'bg-sivarbet-background' : 'bg-transparent'} flex items-center h-full justify-center gap-2 p-1 px-3 md:px-6 rounded-sm cursor-pointer`}
              onClick={() => {
                return setShow('casino');
              }}
            >
              <p className="text-sivarbet-secondary-text text-lg">Casino</p>
            </div>
            <div
              className={`${show !== 'deportes' ? 'bg-sivarbet-background' : 'bg-transparent'} flex items-center h-full justify-center gap-2 p-1 px-3 md:px-6 rounded-sm cursor-pointer`}
              onClick={() => {
                return setShow('deportes');
              }}
            >
              <p className="text-sivarbet-secondary-text text-lg">Deportes</p>
            </div>
          </div>

          {/* ==================== Bonuses ==================== */}
          <div className="flex flex-col justify-center mt-8 gap-4 w-full px-3">
            {/* ==================== Sports ==================== */}
            {show === 'deportes' && (
              <div>
                {bonuses.sport.map((bonus: any) => {
                  let textBonus = '';

                  switch (bonus.bonus_name) {
                    case 'CreateByCode-Welcome':
                      textBonus = 'Tienes un bono de deportivas por registro. Completa unos simples pasos para activarlo y empezar a disfrutarlo.';
                      break;
                    case 'CreateByDeposit-FirstDeposit':
                      textBonus = 'Tienes un bono de deportivas por tú primer deposito. Completa unos simples pasos para activarlo y empezar a disfrutarlo.';
                      break;
                    default:
                      textBonus = 'Este bono de apuestas deportivas está casi listo para ti. Completa unos pasos sencillos y pronto podrás disfrutarlo.';
                      break;
                  }

                  return (
                    <div
                      key={bonus.id}
                      className="flex items-center justify-between w-full  bg-gradient-to-l from-sivarbet-third-text/50 to-sivarbet-sivarbet-border mt-1 p-2 rounded-sm px-5"

                    >
                      <div className="flex items-center gap-5 w-full justify-between ">
                        <Image src={sportBonus} alt="Sport Bonus" width={45} height={45} className="object-contain " />
                        <p className="text-xs xl:text-sm">
                          {bonus.status === 'Active' ? `¡Tu bono de ${bonus.bonus_balance} L en apuestas deportivas está activo! Prepárate para usarlo y aprovecharlo al máximo.` : textBonus}
                        </p>
                        <div
                          className={`${bonus.status === 'Active' ? 'bg-sivarbet-secondary text-sivarbet-secondary-bg' : 'bg-sivarbet-border text-white'}  px-2 py-1 rounded-sm cursor-pointer`}
                          onClick={() => { return handleBonusClick(bonus.status === 'Active' ? '/deportes' : '/promotions'); }}
                        >
                          <p className="text-sm xl:text-base">{bonus.status === 'Active' ? 'Usar' : 'Info'}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            {/* ==================== Casino ==================== */}
            {show === 'casino' && (
              <div>
                {bonuses.spins.map((bonus: any) => {
                  return (
                    <div
                      key={bonus.id}
                      className="flex items-center justify-between w-full bg-gradient-to-l from-sivarbet-third-text/50 to-sivarbet-sivarbet-border mt-1 p-2 rounded-sm px-5"

                    >
                      <div className="flex items-center gap-5 w-full justify-between">
                        <Image src={coinsBonus} alt="Sport Bonus" width={40} height={40} className="object-contain" />
                        <p className="text-xs xl:text-sm">
                          {bonus.status === 2 ? `¡Tu bono de de ${bonus.spins_number} giros gratis stá activo! Prepárate para usarlo y aprovecharlo al máximo.` : 'Este bono de giros gratis está casi listo para ti. Completa unos pasos sencillos y pronto podrás disfrutarlo.'}
                        </p>
                        <div
                          className={`${bonus.status === 2 ? 'bg-sivarbet-secondary text-sivarbet-secondary-bg' : 'bg-sivarbet-border text-white'}  px-2 py-1 rounded-sm cursor-pointer `}
                          onClick={() => { return bonus.status === 2 ? handleFreespinsClick(bonus.games[0]) : handleBonusClick('/promotions'); }}
                        >
                          <p className="text-sm xl:text-base">{bonus.status === 2 ? 'Gira' : 'Info'}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
