import React from 'react';

import Link from 'next/link';

import { DialogTrigger } from '@/components/ui/dialog';

const WebView = () => {
  return (
    <div className="items-center mx-[17px]">
      <div className=" mt-2 destructive group border-destructive bg-destructive text-destructive-foreground p-4 rounded-xl text-center ">
        <strong className="block text-lg">¡Actualiza tu experiencia!</strong>
        <p className="p-0 m-0">Desinstala la versión antigua de nuestra APP y descarga la nueva</p>
        <p className="p-0 m-0">No te pierdas las últimas novedades</p>
        <DialogTrigger asChild>
          <Link href="/landing/app" className="bg-[#c02c2c] p-2 rounded-md block mt-4 font-bold">Descárgala aquí</Link>
        </DialogTrigger>
      </div>
    </div>
  );
};

export default WebView;
