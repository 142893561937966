import { memo } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { z } from 'zod';

import { phoneRecover } from '@/features/auth/schema';

import { core, messages } from '@/features/dashboard/localization';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl, FormField, FormItem, FormMessage,
} from '@/components/ui/form';

interface Props {
  action: (values: z.infer<any>) => void,
  isDisabled: boolean,
}

const ForgotPasswordPhone = ({ action, isDisabled }: Props) => {
  const form = useForm<z.infer<any>>({
    resolver: zodResolver(phoneRecover),
    defaultValues: {
      phone: '',
    },
    mode: 'onChange',
  });
  return (
    <Form
      watch={form.watch}
      getValues={form.getValues}
      getFieldState={form.getFieldState}
      setError={form.setError}
      clearErrors={form.clearErrors}
      setValue={form.setValue}
      trigger={form.trigger}
      formState={form.formState}
      resetField={form.resetField}
      reset={form.reset}
      handleSubmit={form.handleSubmit}
      unregister={form.unregister}
      control={form.control}
      register={form.register}
      setFocus={form.setFocus}
    >
      <form onSubmit={form.handleSubmit(action)}>
        <p className="mb-5">{messages.enterYourMobilePhone}</p>
        <FormField
          control={form.control}
          name="phone"
          render={({ field }) => {
            return (
              <FormItem className="w-full">
                <FormControl className="w-full">
                  <PhoneInput
                    searchClass="!w-full"
                    buttonClass="input-phone-button"
                    dropdownClass="!bg-gray !text-primary_text !h-20 !rounded"
                    inputClass="!w-full ![border:none] ![outline:none] !bg-gray !self-stretch !h-10 !shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] !rounded-lg !flex !flex-row !justify-start !py-[15px] ! px-3.5 !box-border !font-text-sm-medium !font-medium !text-sm !text-slategray !min-w-[212px]"
                    country="hn"
                    onlyCountries={['hn']}
                    placeholder={messages.enterYourMobilePhone}
                    inputProps={{ required: true }}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <Button
          type="submit"
          disabled={isDisabled}
          className="mt-4 w-full bg-[#95fb3f] hover:bg-[#95fb3f] text-sm leading-[10px] font-medium font-text-sm-medium text-sivarbet-background text-center inline-block"
        >
          {isDisabled ? core.validating : messages.validatePhoneNumber }
        </Button>
      </form>

    </Form>
  );
};

export default memo(ForgotPasswordPhone);
