import React, { useState, useEffect } from 'react';

import { CircleAlert } from 'lucide-react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai'; // Import the spinner
import { IoCopyOutline } from 'react-icons/io5';
import { QRCode } from 'react-qrcode-logo';

import { userUIStore } from '@/stores/userUIStore';

import { PAYPHONE_CONVERSION_RATE } from '@/constants/core';

// import {
//   Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue,
// } from '@/components/ui/select';
import {
  Tabs, TabsContent, TabsList, TabsTrigger,
} from '@/components/ui/tabs';
import { useToast } from '@/components/ui/use-toast';

import { Input } from '../../../components/ui/input';
import { createCallbackAddress, fetchCoinRates } from '../services';

interface Props {
  walletAddress: string;
  setWalletAddress: (address: string) => void;
}

const currencies = [
  { value: 'USDT', label: 'USDT' },
  { value: 'BTC', label: 'BTC' },
  { value: 'ETH', label: 'ETH' },
  { value: 'SOL', label: 'SOL' },
];

const networkOptions = {
  USDT: [
    { label: 'TRON', value: 'USDT.TRC20' },
    { label: 'ETHEREUM', value: 'USDT.ERC20' },
    { label: 'POLYGON', value: 'USDT.PRC20' },
    { label: 'SOLANA', value: 'USDT.SOL' },
    { label: 'BSC', value: 'USDT.BEP20' },
  ],
  BTC: [
    { label: 'BTC', value: 'BTC' },
  ],
  ETH: [
    { label: 'ETHEREUM', value: 'ETH' },
    { label: 'BSC', value: 'ETH.BEP20' },
  ],
  SOL: [
    { label: 'SOLANA', value: 'SOL' },
  ],
};

const CryptoQRCode = ({ walletAddress, setWalletAddress }: Props) => {
  const [exchangeRate, setExchangeRate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState<string>('');
  const [availableNetworks, setAvailableNetworks] = useState<any[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeTab, setActiveTab] = useState('default');
  const { setOpenDeposit } = userUIStore();
  const { toast } = useToast();

  const handleCopy = () => {
    navigator.clipboard.writeText(walletAddress).then(() => {
      toast({
        title: 'Dirección copiada',
        description: 'La dirección ha sido copiada al portapapeles',
      });
    });
  };

  const handleCurrencySelect = (e: any) => {
    const newCurrency = e as keyof typeof networkOptions;
    setSelectedCurrency(newCurrency);
    setAvailableNetworks(networkOptions[newCurrency]);
    setSelectedNetwork('');
    setWalletAddress('');
    setExchangeRate(null);
  };

  const handleNetworkSelect = async (e: any) => {
    const networkValue = e;
    setSelectedNetwork(networkValue);
    setIsLoading(true);

    try {
      const data = { currency: selectedCurrency, network: networkValue };
      const callbackAddress = await createCallbackAddress(data);
      setWalletAddress(callbackAddress.address);
    } catch (error: any) {
      toast({
        title: 'Error',
        description: 'Hubo un error al obtener la dirección',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const updateExchangeRate = async (currency: string | number) => {
      const rates = await fetchCoinRates();

      if (rates && rates[currency]) {
        const currencyToBtc = parseFloat(rates[currency].rate_btc);
        const usdToBtc = parseFloat(rates.USD.rate_btc);
        const currencyToUsdRate = currencyToBtc / usdToBtc;
        const usdToHnlRate = 1 / PAYPHONE_CONVERSION_RATE;
        const currencyToHnlRate = currencyToUsdRate * usdToHnlRate;
        setExchangeRate({
          ...rates[currency],
          rate_hnl: currencyToHnlRate.toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
        });
      }
    };
    updateExchangeRate(selectedNetwork);
  }, [selectedNetwork, availableNetworks]);

  return (
    <div className="h-full">
      <Tabs defaultValue="default" className="h-full">
        <TabsList className="grid grid-cols-4 gap-3 w-full h-full bg-sivarbet-secondary-bg py-2 mt-2">
          {currencies.map((currency) => {
            return (
              <TabsTrigger
                key={currency.value}
                value={currency.value}
                className="flex justify-center items-center hover:bg-sivarbet-primary-hover data-[state=active]:bg-sivarbet-primary h-full"
                onClick={() => {
                  setActiveTab(currency.value);
                  handleCurrencySelect(currency.value);
                  setOpenDeposit(true);
                }}
              >
                {currency.label}
              </TabsTrigger>
            );
          })}
        </TabsList>
        <TabsContent value="default" className="h-full">
          <div className="flex flex-col items-center justify-center h-full">
            <span className="text-sivarbet-text text-center mt-10 text-xl">
              Selecciona una criptomoneda para depositar
            </span>
          </div>
        </TabsContent>
        {currencies.map((currency) => {
          return (
            <TabsContent key={currency.value} value={currency.value} className="h-full">
              <div className="flex flex-col h-full p-3">
                <span className="text-start pt-2 text-lg max-md:text-base">
                  Deposita en
                  {' '}
                  {currency.label}
                </span>
                <div className="flex flex-col items-center">
                  <div className="w-full py-2">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="network" className="max-md:text-sm">Selecciona la red</label>
                    <select
                      value={selectedNetwork}
                      onChange={(e) => { return handleNetworkSelect(e.target.value); }}
                      className="w-full bg-sivarbet-secondary-bg border-none mb-2 mt-1 p-3 rounded-lg"
                    >
                      <option value="" disabled>
                        Selecciona la red
                      </option>
                      {availableNetworks.map((network: { label: string; value: string }) => {
                        return (
                          <option key={network.value} value={network.value}>
                            {network.label}
                          </option>
                        );
                      })}
                    </select>

                    {/* Conditionally render loading spinner or the content */}
                    {isLoading ? (
                      <div className="flex justify-center items-center mt-6">
                        <AiOutlineLoading3Quarters className="animate-spin text-sivarbet-primary text-5xl" />
                      </div>
                    ) : (
                      <>
                        <div className="pt-2">
                          {exchangeRate && (
                            <div className="text-sivarbet-secondary-text text-sm font-normal max-md:text-sm">
                              <span>
                                1
                                {selectedCurrency}
                                {' '}
                                =
                                {' '}
                              </span>
                              <span>
                                {(exchangeRate as any)?.rate_hnl}
                                {' '}
                                Lempiras
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="wallet-address w-full">
                          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                          <label
                            htmlFor="walletAddress"
                            className="text-sivarbet-primary text-sm font-normal max-md:text-sm"
                          >
                            Tu dirección personal
                          </label>
                          <div className="flex items-center flex-row-reverse">
                            <Input
                              aria-labelledby="walletAddressLabel"
                              type="text"
                              readOnly
                              onCopy={handleCopy}
                              value={walletAddress}
                              id="walletAddress"
                              className="w-full bg-sivarbet-secondary-bg border-none text-sivarbet-text relative"
                            />
                            <IoCopyOutline className="absolute text-sivarbet-text cursor-pointer hover:text-sivarbet-primary mx-3" onClick={handleCopy} />
                          </div>
                        </div>

                        <div className="flex flex-col items-center py-2">
                          {walletAddress && (
                            <QRCode
                              value={walletAddress}
                              size={125}
                              qrStyle="dots"
                              eyeRadius={10}
                              quietZone={10}
                              style={{ Border: '1px', borderRadius: '5px' }}
                            />
                          )}
                          <p className="text-center text-base font-normal leading-5 pt-2 max-md:text-sm">
                            El valor de
                            {' '}
                            {selectedCurrency}
                            {' '}
                            puede cambiar entre ahora y el
                            momento en que recibamos tu depósito.
                          </p>
                        </div>

                        <div className="flex items-center gap-4 rounded-md border border-sivarbet-border px-5 py-1">
                          <CircleAlert className="text-[#807D96] w-12" />
                          <span className="text-xs max-md:text-xs text-[#807D96] font-medium">
                            ¡Ten en cuenta! El valor del depósito no incluye la tarifa de
                            transferencia. Por favor asegúrese de transferir el monto con la
                            tarifa de transferencia incluida.
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </TabsContent>
          );
        })}
      </Tabs>
    </div>
  );
};

export default CryptoQRCode;
