'use client';

import React from 'react';

import Link from 'next/link';

import { usePathname } from 'next/navigation';

// import { GiPokerHand } from 'react-icons/gi';
import { IoHomeOutline } from 'react-icons/io5';
import { MdOutlineSportsVolleyball } from 'react-icons/md';
import { SlGameController } from 'react-icons/sl';

import DiscoverSheet from '@/components/sheet/Discover';

const NavigationBar = () => {
  const pathname = usePathname();

  return (
    <div className="fixed z-10 bottom-0 shadow-xl shadow-black/50 w-full bg-sivarbet-background border-t border-sivarbet-border text-sivarbet-text flex justify-around items-end py-2 px-4 lg:hidden mt-1 pb-4">
      <Link href="/" className={`hover:text-sivarbet-primary flex flex-col items-center text-sm ${pathname === '/' ? 'text-sivarbet-primary' : ''}`}>
        <IoHomeOutline className="text-xl" />
        <span>Home</span>
      </Link>
      <Link href="/casino" className={`hover:text-sivarbet-primary flex flex-col items-center text-sm ${pathname === '/casino' ? 'text-sivarbet-primary' : ''}`}>
        {/* <GiPokerHand className="text-2xl" /> */}
        <SlGameController className="text-lg" />
        <span>Casino</span>
      </Link>
      <Link href="/deportes" className={`hover:text-sivarbet-primary flex flex-col items-center text-sm ${pathname === '/deportes' ? 'text-sivarbet-primary' : ''}`}>
        <MdOutlineSportsVolleyball className="text-xl" />
        <span>Deportes</span>
      </Link>
      <DiscoverSheet />
    </div>
  );
};

export default NavigationBar;
