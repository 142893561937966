import React from 'react';

import Link from 'next/link';

import { OptionsSidebar } from '@/interfaces/core';

import { FUTBOL_OPTION } from '@/constants/core';

import DropdownItem from '@/components/items/DropdownItem';

import LinkItem from '@/components/items/LinkItem';
import {
  Accordion, AccordionContent, AccordionItem, AccordionTrigger,
} from '@/components/ui/accordion';
import { Separator } from '@/components/ui/separator';
import { TabsContent } from '@/components/ui/tabs';

const SportsTab = ({ isOpen, arrOptions }: { isOpen: boolean, arrOptions: OptionsSidebar[] }) => {
  return (
    <TabsContent value="sport" className="flex flex-col bg-sivarbet-background rounded-xl">
      {isOpen ? (
        <>
          <span className="px-4 py-3 text-base font-medium">Deportes</span>
          <Separator className="bg-sivarbet-border border-sivarbet-border border-[1px]" />
          <Accordion type="single" collapsible className="w-full z-10">
            <AccordionItem value={FUTBOL_OPTION.title} className="border-none">
              <AccordionTrigger className="flex justify-between px-4 hover:no-underline hover:!bg-sivarbet-border">
                <div className="flex items-center justify-center gap-2 text-base">
                  <span className="fill-white icon icon-ball text-base hover:no-underline" />
                  {FUTBOL_OPTION.title}
                </div>
              </AccordionTrigger>
              <AccordionContent className="p-1 shadow-md bg-sivarbet-border rounded-b-xl">
                <ul className="px-4 py-2 space-y-2">
                  {FUTBOL_OPTION.list.map((item) => {
                    return (
                      <li
                        key={item.link}
                        className="hover:bg-sivarbet-primary rounded-xl transition duration-2000 flex"
                      >
                        <Link
                          href={item.link}
                          className="flex  items-center  gap-2 py-2 px-3 text-sivarbet-secondaryext hover:text-sivarbet-border transition duration-200 rounded-md text-xs"
                        >
                          <span className={`icon ${item.icon} text-lg`} />
                          {item.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
          {arrOptions.filter((link) => { return link.type === 'sport'; }).map((link) => {
            return (
              <LinkItem key={link.title} link={link} isCollapsed={!isOpen} />
            );
          })}
        </>
      ) : (
        <div className="flex flex-col items-center">
          <DropdownItem link={FUTBOL_OPTION} isCollapsed={!isOpen} />
          {arrOptions.filter((link) => { return link.type === 'sport'; }).map((link) => {
            return (
              <LinkItem key={link.title} link={link} isCollapsed={!isOpen} />
            );
          })}
        </div>
      )}
    </TabsContent>
  );
};

export default SportsTab;
