import { memo } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { emailRecover } from '@/features/auth/schema';

import { core, messages } from '@/features/dashboard/localization';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl, FormField, FormItem, FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';

interface Props {
  action: (values: z.infer<any>) => void,
  isDisabled: boolean,
}

const ForgotPasswordEmail = ({ action, isDisabled }: Props) => {
  const form = useForm<z.infer<any>>({
    resolver: zodResolver(emailRecover),
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
  });
  return (
    <Form
      watch={form.watch}
      getValues={form.getValues}
      getFieldState={form.getFieldState}
      setError={form.setError}
      clearErrors={form.clearErrors}
      setValue={form.setValue}
      trigger={form.trigger}
      formState={form.formState}
      resetField={form.resetField}
      reset={form.reset}
      handleSubmit={form.handleSubmit}
      unregister={form.unregister}
      control={form.control}
      register={form.register}
      setFocus={form.setFocus}
    >

      <form onSubmit={form.handleSubmit(action)}>
        <div>
          <p className="mb-5">{messages.enterYourEmail}</p>
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={messages.enterYourEmail}
                      onChange={(value) => { field.onChange(value.target.value.toLowerCase()); }}
                      value={field.value}
                      name={field.name}
                      type="email"
                      onBlur={field.onBlur}
                      className="input-form-text"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <Button
            type="submit"
            disabled={isDisabled}
            className="mt-4 w-full bg-[#95fb3f] hover:bg-[#95fb3f] text-sm leading-[10px] font-medium font-text-sm-medium text-sivarbet-background text-center inline-block"
          >
            {isDisabled ? core.sending : core.sendEmailPassRecover }
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default memo(ForgotPasswordEmail);
