export const messages = {
  errorInvalidNameMin: 'El nombre debe tener al menos 2 caracteres',
  errorInvalidNameMax: 'El nombre debe tener como máximo 50 caracteres',
  errorInvalidSecondNameMin: 'El segundo nombre debe tener al menos 2 caracteres',
  errorInvalidSecondNameMax: 'El segundo nombre debe tener como máximo 50 caracteres',
  errorInvalidLastNameMin: 'El apellido debe tener al menos 2 caracteres',
  errorInvalidLastNameMax: 'El apellido debe tener como máximo 50 caracteres',
  errorInvalidBirthdate: 'La fecha de nacimiento es requerida',
  errorInvalidBirthdateMin: 'Debe ser mayor de edad',
  errorInvalidCountry: 'El país es requerido',
  firstName: 'Primer Nombre',
  secondName: 'Segundo nombre',
  lastName: 'Apellido',
  birthdate: 'Fecha de nacimiento',
  country: 'País',
  clubFavorite: 'Club favorito',
  selectCountry: 'Selecciona un país',
  selectClub: 'Selecciona un club',
  completeForm: '¡Ya casi tienes tu regalo!',
  thanksRegister: '¡Gracias por registrarte en nuestra plataforma! Tu regalo te está esperando. Solo necesitas verificar tu correo para comenzar a disfrutar de todos nuestros beneficios. Haz clic en el botón "Verificar" y accede a lo que tenemos preparado para ti.',
  inviteComplete: 'Lo invitamos a completar su información personal para empezar a jugar.',
  completeButton: 'Verificar',
  titleToastSucces: 'Datos completados con exito',
  descriptionToastSucces: '¡Tu registro ha sido completado con éxito!',
  titleToastError: 'Error al completar el registro',
  descriptionToastError: '¡Ha ocurrido un error al completar el registro, por favor, intenta más tarde.',
  completeFormGame: 'Completa tu registro para empezar a jugar',
  thanksRegisterGame: 'Por favor complete su información personal y realice su primer depósito para empezar a disfrutar de los juegos de casino.',
  completeButtonGame: 'Completar información',
};
