'use client';

import React, { useState, useEffect } from 'react';

import Image from 'next/image';

import { useSession } from 'next-auth/react';

import { FaEnvelope } from 'react-icons/fa6';

import { useAuthStore } from '@/stores/userStore';

import { decrypt, exception } from '@/utils/core';

import { LOCAL } from '@/constants/core';

import { Button } from '../ui/button';
import { Dialog, DialogContent } from '../ui/dialog';
import {
  DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { ScrollArea } from '../ui/scroll-area';
import { Separator } from '../ui/separator';
import { useToast } from '../ui/use-toast';

const mockMessages = [
  {
    id: '1',
    isOpened: false,
    create_time: 1718304000, // Represents a date in UNIX timestamp format
    message: {
      title: <strong>Welcome to Xtremepush!</strong>,
      alert: <p>Discover how you can engage effectively with your users.</p>,
      icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpBaT9f8ZECn1Vhi3Ep9YVnuKsKontXj1F5Q&s',
    },
  },
  {
    id: '2',
    isOpened: false,
    create_time: 1718400000,
    message: {
      title: <strong>Get Started with our SDK</strong>,
      alert: <p>Integrate our SDK to leverage the full power of our platform.</p>,
      icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpBaT9f8ZECn1Vhi3Ep9YVnuKsKontXj1F5Q&s',
    },
  },
  {
    id: '3',
    isOpened: true,
    create_time: 1718500000,
    message: {
      title: <strong>Latest Features</strong>,
      alert: <p>Check out the latest features we&apos;ve released!</p>,
      icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpBaT9f8ZECn1Vhi3Ep9YVnuKsKontXj1F5Q&s',
    },
  },
];

const Notifications = () => {
  const [notifications, setNotifications] = useState<any[]>([]);
  const [expandedId, setExpandedId] = useState(null);
  const [isxtremepushSet, setXtremepush] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [imgUrl, setImgUrl] = useState('');
  const { toast } = useToast();
  const { data: session } = useSession();
  const { notifyAccepted, setNotifyAccepted } = useAuthStore();

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_APP_ENV === LOCAL) {
      setNotifications(mockMessages);
    }
    const fetchMessages = async () => {
      const userId = await decrypt(session?.user?.data ?? '', session?.user?.tag ?? '');
      if ((window as any).xtremepush) {
        if (!isxtremepushSet) {
          (window as any).xtremepush('set', 'user_id', userId);
          setXtremepush(true);
        }
        await (window as any).xtremepush('inbox', 'message.list', {
          limit: 10,
          offset: 0,
        }, (result: any) => {
          if (result.items && result.items.length > 0) {
            setNotifications(result.items.map((item: any) => { return { ...item, isOpened: false }; }));
          }
        }, async (error: any) => {
          await exception(error, { route: '/components/select/Notifications.tsx: fetchMessages', method: 'GET', req: null });
        });
      } else {
        setXtremepush(false);
      }
    };
    if (session?.user?.data && session?.user?.tag) {
      fetchMessages();
    }
  }, [isxtremepushSet, session?.user?.data, session?.user?.tag, setXtremepush]);

  const handleNotify = () => {
    if ((window as any).xtremepush) {
      (window as any).xtremepush('ready', () => {
        // Checking current user permission

        const permission = (window as any).xtremepush('push', 'permission');

        if (permission === 'default') {
          (window as any).xtremepush('push', 'prompt', {
            allowCallback() {
              // User clicked allow. Removing subscribe button
              setNotifyAccepted();
              toast({
                title: 'Notificaciones Acceptadas',
                description: 'Ya has aceptado las notificaciones exitosamente!',
                className: 'bg-sivarbet-secondary',
              });
            },
            denyCallback() {
              // User clicked deny. Removing subscribe button
              setNotifyAccepted();
              toast({
                title: 'Notificaciones Rechazas',
                description: 'Ya has rechazado las notificaciones',
                variant: 'destructive',
              });
            },
            dismissCallback() {
              // User dismissed dialog. It is possible to prompt again later
            },
          });
        } else {
          switch (permission) {
            case 'granted':
              setNotifyAccepted();
              toast({
                title: 'Notificaciones Acceptadas',
                description: 'Ya has aceptado las notificaciones exitosamente!',
                className: 'bg-sivarbet-secondary',
              });
              break;
            case 'denied':
              setNotifyAccepted();
              toast({
                title: 'Notificaciones Rechazas',
                description: 'Ya has rechazado las notificaciones',
                variant: 'destructive',
              });
              break;
            case 'unavailable':
            // Push messaging is not available in user browser
              break;
            default:
              break;
          }
        }
      });
    }
  };

  const handleToggleMessage = (id: React.SetStateAction<null>) => {
    const message = notifications.find((notif) => { return notif.id === id; });
    const isExpanded = expandedId === id;
    setExpandedId(isExpanded ? null : id); // Toggle expansion

    if (!isExpanded && !message.isOpened) {
      // @ts-ignore
      xtremepush('inbox', 'message.action', {
        id,
        open: 1, // Mark as opened
      }, () => {
        // Mark the message as opened in the state
        const updatedNotifications = notifications.map((notif) => {
          return (notif.id === id ? { ...notif, opened: 1, isOpened: true } : notif);
        });
        setNotifications(updatedNotifications);
      }, (error: any) => {
        // eslint-disable-next-line no-console
        console.error('Failed to mark message as opened:', error);
      });
    }
  };

  const timeAgo = (time: number) => {
    const now = new Date();
    const createTime = new Date(time * 1000);
    const timeDiff = now.getTime() - createTime.getTime();
    return Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger data-testid="notifications-trigger" className="max-lg:hidden">
          {
            (notifications.some((notification) => {
              return notification.opened !== 1;
            })
            ) ? (
            // eslint-disable-next-line react/jsx-indent
                <div className="flex flex-row-reverse">
                  <div className="w-2 h-2 rounded-full bg-sivarbet-secondary z-10 absolute" />
                  <FaEnvelope className="text-sivarbet-text text-2xl mr-[3px]" />
                </div>
              ) : (
                <FaEnvelope className="text-sivarbet-text text-2xl" />
              )
          }
        </DropdownMenuTrigger>
        <DropdownMenuContent className="bg-sivarbet-background text-sivarbet-text border-sivarbet-border pb-0 px-4 mr-7 w-[600px]  max-h-[80vh] flex flex-col">
          <ScrollArea className="w-full h-96">
            {notifications.length > 0 ? (
              <>
                {notifications.map((notification) => {
                  return (
                    <div key={notification.id}>
                      <DropdownMenuItem
                        key={notification.id}
                        className={`flex flex-col items-start focus:text-sivarbet-text focus:bg-sivarbet-secondary-bg hover:bg-sivarbet-secondary-bg ${!notification.opened ? 'bg-sivarbet-secondary-bg' : ''}`}
                        onClick={() => {
                          handleToggleMessage(notification.id);
                        }}
                      >
                        <div className="flex justify-between gap-4 w-full">
                          <div className="flex flex-col">
                            {/* eslint-disable-next-line react/no-danger */}
                            <div dangerouslySetInnerHTML={{ __html: notification.message.title || notification.title }} />
                            {/* eslint-disable-next-line react/no-danger */}
                            <div dangerouslySetInnerHTML={{ __html: notification.message.alert || notification.alert }} />
                          </div>
                          <div className="flex flex-col items-center">
                            <div className="flex flex-col items-center">
                              <div className="flex items-center gap-3">
                                <span className="text-xs text-sivarbet-secondaryext">
                                  Hace
                                  {' '}
                                  {timeAgo(notification.create_time)}
                                  {' '}
                                  dias
                                </span>
                                <div className={`w-2 h-2 rounded-full bg-sivarbet-secondary ${notification.opened === 1 ? 'hidden' : ''}`} />
                              </div>
                              <Image src={notification.message.icon} alt="notification-image" width={100} height={100} onClick={() => { setImgUrl(notification.message.icon); setIsNotificationOpen(true); }} />
                            </div>
                          </div>
                        </div>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator className="bg-sivarbet-border mb-0" />
                    </div>
                  );
                })}
              </>
            ) : (
              <DropdownMenuItem className="flex flex-col items-center justify-center gap-4 focus:bg-transparent hover:bg-transparent">
                <span>No notifications</span>
                <span className="icon icon-bell_notification text-9xl">
                  <span className="path1 text-sivarbet-text" />
                  <span className="path2 text-sivarbet-text" />
                </span>
              </DropdownMenuItem>
            )}
            {!notifyAccepted && (
              <>
                <Separator className="bg-sivarbet-border my-3" />
                <div className="w-full flex items-center justify-around">
                  <Button id="btn-notify" onClick={() => { return handleNotify(); }} className="bg-sivarbet-primary">
                    Suscribirse a las notificaciones
                  </Button>
                </div>
              </>
            )}
          </ScrollArea>
        </DropdownMenuContent>
      </DropdownMenu>
      <Dialog open={isNotificationOpen} onOpenChange={setIsNotificationOpen}>
        <DialogContent className="p-0 border-none rounded-lg bg-transparent">
          <Image src={imgUrl} alt="notification-image" width={100} height={100} className="w-full h-full rounded-lg" />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Notifications;
