import { memo, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Eye, EyeOff } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { newPassRecover } from '@/features/auth/schema';

import { core, messages } from '@/features/dashboard/localization';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl, FormField, FormItem, FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';

interface Props {
  action: (values: z.infer<any>) => void,
  isDisabled: boolean,
}

const ForgotPasswordChange = ({ action, isDisabled }: Props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState<boolean>(false);

  const form = useForm<z.infer<any>>({
    resolver: zodResolver(newPassRecover),
    defaultValues: {
      phone: '',
    },
    mode: 'onChange',
  });
  return (
    <Form
      watch={form.watch}
      getValues={form.getValues}
      getFieldState={form.getFieldState}
      setError={form.setError}
      clearErrors={form.clearErrors}
      setValue={form.setValue}
      trigger={form.trigger}
      formState={form.formState}
      resetField={form.resetField}
      reset={form.reset}
      handleSubmit={form.handleSubmit}
      unregister={form.unregister}
      control={form.control}
      register={form.register}
      setFocus={form.setFocus}
    >
      <form onSubmit={form.handleSubmit(action)}>
        <p className="mb-5">{messages.enterYourPassword}</p>
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => {
            return (
              <FormItem className="w-full">
                <FormControl>
                  <div className="relative">
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      placeholder={messages.enterYourPassword}
                      onChange={field.onChange}
                      value={field.value}
                      name={field.name}
                      onBlur={field.onBlur}
                      className="input-form-text pr-10"
                    />
                    <button
                      type="button"
                      onClick={() => { setShowPassword(!showPassword); }}
                      className="absolute right-3 top-1/2 transform -translate-y-1/2"
                    >
                      {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                    </button>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />

        <p className="mb-5">{messages.enterYourPasswordConfirm}</p>
        <FormField
          control={form.control}
          name="passwordConfirm"
          render={({ field }) => {
            return (
              <FormItem className="w-full">
                <FormControl>
                  <div className="relative">
                    <Input
                      type={showPasswordConfirm ? 'text' : 'password'}
                      placeholder={messages.enterYourPasswordConfirm}
                      onChange={field.onChange}
                      value={field.value}
                      name={field.name}
                      onBlur={field.onBlur}
                      className="input-form-text pr-10"
                    />
                    <button
                      type="button"
                      onClick={() => { setShowPasswordConfirm(!showPasswordConfirm); }}
                      className="absolute right-3 top-1/2 transform -translate-y-1/2"
                    >
                      {showPasswordConfirm ? <EyeOff size={20} /> : <Eye size={20} />}
                    </button>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />

        <Button
          type="submit"
          disabled={isDisabled}
          className="mt-4 w-full bg-[#95fb3f] hover:bg-[#95fb3f] text-sm leading-[10px] font-medium font-text-sm-medium text-sivarbet-background text-center inline-block"
        >
          {isDisabled ? core.processing : messages.changePassword }
        </Button>
      </form>

    </Form>
  );
};

export default memo(ForgotPasswordChange);
