export const core = {
  accountConfiguration: 'Configuración de cuenta',
  accountConfigurationUrl: 'dashboard',
  identityDetails: 'Detalles de identidad',
  identityDetailsUrl: 'identity-details',
  transactionHistory: 'Historial de transacciones',
  transactionHistoryUrl: 'transactions',
  withdraw: 'Retirar',
  withdrawUrl: 'withdrawals',
  myPromotions: 'Mis promociones',
  myPromotionsUrl: 'promotions',
  bonuses: 'Bonificaciones',
  bonusesUrl: 'bonuses',
  notifications: 'Notificaciones',
  notificationsUrl: 'notifications',
  logout: 'Cerrar sesión',
  verifyAccount: 'Verifica tu cuenta',
  completeProfile: 'Completar perfil',
  deposit: 'Depositar',

  verifyIdentity: 'Verificar identidad',
  verifiedProfile: 'Perfil verificado',
  statusApproved: 'Verificado',
  statusPending: 'Estado: Pendiente',
  statusRejected: 'Estado: Rechazado',
  statusInitial: 'Estado: No verificado',

  sendVerification: 'Enviar verificación',
  enterYourEmail: 'Ingrese su correo electrónico',
  resendVerification: 'Reenviar verificación',
  verified: 'Verificado',
  verifyEmail: 'Verificar email',
  update: 'Actualizar',
  completed: 'Completado',
  profileCompleted: 'Perfil completo',
  firstDepositCompleted: 'Primer depósito realizado',
  changeYourEmail: 'Cambia tu correo electronico',
  email: 'Correo',
  accountSettings: 'Configuración de cuenta',
  security: 'Seguridad',
  changePassword: 'Cambia tu contraseña',
  change: 'Cambiar contraseña',
  sending: 'Enviando...',
  validating: 'Validando...',
  processing: 'Procesando...',
  sendEmailPassRecover: 'Enviar correo de recuperación',
  twoFactorAuthentication: 'Autenticación en dos pasos',
  advanced: 'Avanzado',
  deactivateAccount: 'Desactivar cuenta',
  firstDeposit: 'Primer deposito',
  shareProfile: 'Compartir perfil',
  cash: 'Efectivo',
  bank: 'Banco',
  wallet: 'Wallet',
  verifiedEmail: 'Correo verificado',
};
